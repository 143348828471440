export const SITE_NAME = "Conseil Régional du tourisme Beni Mellal Khenifra";
export const SITE_LOGO = "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1626719259799.png";
export const SITE_URL = 'https://visitbenimellal.ma'
export const PERSISTANCE_KEY = "vbmk-admin-005";
export const API_ENDPOINT =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:4040"
    : "https://staging-api-visitbenimellal-ma.cloud.marrakech.io";
export const API_PLACES = API_ENDPOINT + "/places";
export const API_TAGS = API_ENDPOINT + "/tags";
export const API_ARTICLES = API_ENDPOINT + "/articles";
export const API_CITY = API_ENDPOINT + "/city";
export const API_STORIES = API_ENDPOINT + "/stories";
export const API_TOURS = API_ENDPOINT + "/tours";
export const API_MICE = API_ENDPOINT + "/mice";
export const API_NEWSLETTER = API_ENDPOINT + "/newsletter";
export const API_NEWSLETTER_SEND = API_ENDPOINT + "/newsletter/send";
export const API_AUTH = API_ENDPOINT + "/auth/login";
export const API_AUTH_IS_LOGGED_IN = API_ENDPOINT + "/auth/isLoggedIn"; //Not implemented yet
export const API_UPLOAD = API_ENDPOINT + "/media";
export const API_CIRCUITS = API_ENDPOINT + "/circuits"; //randonnées
export const API_INSTAWALL = API_ENDPOINT + "/instawall";
export const NO_IMAGE =
  "https://productionvisittanger.s3.eu-central-1.amazonaws.com/uploads/1579059018898.png";
export const JSON_HEADERS = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
};

export function getSafe(fn, defaultVal) {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
}

export function slugify(text) {
  if (!text) return;
  return (
    text
      .toString()
      .toLowerCase()
      .replace(new RegExp("[àáâãäå]", "g"), "a") // handling diactritics
      .replace(new RegExp("æ", "g"), "ae")
      .replace(new RegExp("ç", "g"), "c")
      .replace(new RegExp("[èéêë]", "g"), "e")
      .replace(new RegExp("[ìíîï]", "g"), "i")
      .replace(new RegExp("ñ", "g"), "n")
      .replace(new RegExp("[òóôõö]", "g"), "o")
      .replace(new RegExp("œ", "g"), "oe")
      .replace(new RegExp("[ùúûü]", "g"), "u")
      .replace(new RegExp("[ýÿ]", "g"), "y")
      .replace(/\s+/g, "-") // Replace spaces with -
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      // eslint-disable-next-line
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "")
  ); // Trim - from end of text
}

export const CITIES = [
  {
    label: "tanger",
    name: "Tanger",
  },
  {
    label: "tetouan",
    name: "Tétouan",
  },
  {
    label: "alhoceima",
    name: "Al Hoceima",
  },
  {
    label: "chefchaen",
    name: "Chefchaen",
  },
  {
    label: "asilah",
    name: "Asilah",
  },
];

export const PLACES_DATA_SKELETON = [
  {
    featured: false,
    subtitle: {
      en: null,
      fr: null,
      es: null,
      ar: null,
      zh: null,
    },
    title: {
      en: null,
      fr: null,
      es: null,
      ar: null,
      zh: null,
    },
    description: {
      en: null,
      fr: null,
      es: null,
      ar: null,
      zh: null,
    },
    tags: [
      {
        id: null,
        slug: null,
      },
    ],
    media: [{ url: null, size: null }],
    location: {
      type: "Point",
      coordinates: [],
    },
    id: null,
    status: "draft",
    contact: {
      name: null,
      email: null,
      phone: null,
      url: null,
      address: {
        street_address: null,
        postal_code: null,
        city: null,
      },
    },
    misc: {
      minVisitDuration: null,
      offerCondition: null,
      cost: null,
      bias: null,
      HotelRating: null,
      EventStartDate: null,
      EventEndDate: null,
      priceRange: null,
      suitableForDisabled: null,
      wifi: null,
      alcool: null,
    },
    openingHours: {
      0: {
        opens: null,
        closes: null,
        is24: null,
      },
      1: {
        opens: null,
        closes: null,
        is24: null,
      },
      2: {
        opens: null,
        closes: null,
        is24: null,
      },
      3: {
        opens: null,
        closes: null,
        is24: null,
      },
      4: {
        opens: null,
        closes: null,
        is24: null,
      },
      5: {
        opens: null,
        closes: null,
        is24: null,
      },
      6: {
        opens: null,
        closes: null,
        is24: null,
      },
      exceptions: null,
    },
  },
];

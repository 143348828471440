import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchSetEmails, sendEmail } from "../../actions/newsletter";
import { Button, Row, Col, Input } from "antd";
import { getSafe, SITE_URL } from "../../constants";
import Spacer from "../../components/Spacer";
import Layout from "../../components/Layout";
import { emailTemplate } from "./template/index";
const DEFAULT_BG = "https://d3lq8p6p6r1qxf.cloudfront.net/1628964638443.png";

const { TextArea } = Input;

function broadcastEmail({
  emails,
  token,
  defaultEmailConfig,
  incrementCounter=()=>{},
  sendEmail = (e) => {
    console.log(e);
  },
  places,
}) {
  /**
   *
   * Hero section
   *
   * */
  const heroTitle = (user = {}) => {
    if (user.stade === "planning") {
      return "Still Planning?";
    }

    if (user.stade === "visiting") {
      return "Did you enjoy the region?";
    }

    return "";
  };

  const heroBtnTitle = (user = {}) => {
    if (user.stade === "planning") {
      return "Try Trip Planner";
    }

    if (user.stade === "visiting") {
      return "Did you Try Mapless Navigation?";
    }

    return "";
  };

  function getRandomArbitrary(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

  /**
   *
   *  POI section
   *
   * */
  const genPoi = (user = {}, places, defaultTag = "culture") => {
    const tag = getSafe(user.tags[0]) || defaultTag;
    const filteredPlaces = places ? places.filter((p) => {
      if (p && p.tags && p.tags[0]) {
        return p.tags[0].slug===tag
      }
      return false
    }) : [];

    const onePlace = filteredPlaces[getRandomArbitrary(0,filteredPlaces.length-1)]

    return {
      src: getSafe(() => onePlace.media[0]["url"]) || DEFAULT_BG,
      title: getSafe(() => onePlace.title["en"]),
    };
  };

  /**
   *
   * Loop
   */

  emails.forEach((e, k) => {
    const poiOne = genPoi(e.user,  places, "culture");
    const poiTwo = genPoi(e.user,  places, "nature");

    /** */
    console.log("heroTitle", heroTitle(e.user));
    console.log("heroBtnTitle", heroBtnTitle(e.user));
    console.log("poiOne", poiOne);
    console.log("poiTwo", poiTwo);
    /** */

    if (e && e.user && e.email) {
      setTimeout(() => {
        sendEmail(token, 
         { to: e.email,
          subject: defaultEmailConfig.sectionOneTitle,
          message: emailTemplate({
            ...defaultEmailConfig,
            heroBtnTitle: heroBtnTitle(e.user),
            heroTitle: heroTitle(e.user),
            poiOneSrc: poiOne.src,
            poiOneTitle: poiOne.title,
            poiTwoSrc: poiTwo.src,
            poiTwoTitle: poiTwo.title,
          }),
          });
          incrementCounter(k + 1)
      }, (k + 1) * 1000);
    }

  });
}

function Tags({ emails, token, fetchSetEmails, places, cities }) {
  const [sending, setSending] = useState(false)
  const [counter, setCounter] = useState(0)
  function incrementCounter(x){
    setCounter(x)
  }
  const onSubmit = () => {
    setSending(true)
    broadcastEmail({ emails, defaultEmailConfig, places, cities, sendEmail:sendEmail, token, incrementCounter });
  };

  const [defaultEmailConfig, setDefaultEmailConfig] = useState({
    heroBg: DEFAULT_BG, //dyn
    heroTitle: "-", //dyn
    heroTitleUrl: SITE_URL,
    heroBtnTitle: "Some suggestions",
    sectionOneTitle: "Welcome To Beni-Mellal / Khénifra",
    sectionOneSubtitle: "",
    sectionOneContent: "",
    poiOneSrc: DEFAULT_BG,
    poiOneTitle: "-",
    poiOneButtonUrl: SITE_URL,
    poiOneButtonTitle: "Discover",
    poiTwoTitle: "-",
    poiTwoSrc: DEFAULT_BG,
    poiTwoBtnUrl: SITE_URL,
    poiTwoBtnTitle: "Discover",
  });

  //get Email Database
  useEffect(() => {
    fetchSetEmails(token);
  }, [fetchSetEmails, token]);

  return (
    <Layout>
      {/**Form */}
      <Spacer bottom />
      <Row gutter={12}>
        <Col md={8}>
          {
            sending && <div>
              <h1 style={{ fontSize: '180px' }}>{counter}</h1>
              <Button onClick={() => {setSending(false); setCounter(0)}}>Reset</Button>
            </div>
            
          }
        { !sending && <>
          <Input
            placeholder={"Title"}
            onChange={(event) =>
              setDefaultEmailConfig({
                ...defaultEmailConfig,
                sectionOneTitle: event.target.value,
              })
            }
          />
          <Spacer customb="10px" />

          <Input
            placeholder={"Subtitle"}
            onChange={(event) =>
              setDefaultEmailConfig({
                ...defaultEmailConfig,
                sectionOneSubtitle: event.target.value,
              })
            }
          />
          <Spacer customb="10px" />

          <TextArea
            rows={20}
            placeholder={"Custom message"}
            onChange={(event) =>
              setDefaultEmailConfig({
                ...defaultEmailConfig,
                sectionOneContent: event.target.value,
              })
            }
          />
          <Spacer customb="10px" />
          <Button
            type="primary"
            style={{ borderRadius: 0 }}
            block
            size={"large"}
            onClick={()=>onSubmit()}
          >
            Send
          </Button>
          </>}
        
        </Col>

        <Col md={16}>
          <h1 style={{ margin: 0 }}>Demo Preview</h1>
          <p>This will be customized for each customer</p>
          <Spacer customb={"20px"} />
          <div
            style={{
              overflowX: "auto",
              width: "100%",
              overflowY: "auto",
              height: "500px",
            }}
            dangerouslySetInnerHTML={{
              __html: emailTemplate(defaultEmailConfig),
            }}
          />
        </Col>
      </Row>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  ...state.Auth,
  ...state.Cities,
  ...state.Newsletter,
  ...state.Places,
  ...state.Cities,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSetEmails: (token) => dispatch(fetchSetEmails(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
